const pageView = data => {
    try {
        if (window.axon) {
            const {
                productId,
                amount,
                currency
            } = data
            window.axon("track", "view_item", {
                "currency": currency, "value": amount, items: [{
                    "item_id": productId,
                    "item_name": productId,
                    "price": amount,
                    "quantity": 1,
                    "item_category_id":166
                }]
            });
        }
    } catch (e) {
        console.warn(e)
    }
}

const addToCart = data => {
    try {
        if (window.axon) {
            const {
                productId,
                amount,
                currency,
                quantity
            } = data

            window.axon("track", "add_to_cart", {
                "currency": currency, "value": amount, items: [{
                    "item_id": productId,
                    "item_name": productId,
                    "price": amount,
                    "quantity": quantity,
                    "item_category_id":166
                }]
            });
        }
    } catch (e) {
        console.warn(e)
    }
}

const purchase = data => {
    try {
        console.log("axon------------------------------------------")
        if (window.axon) {
            const {
                transactionId,
                orderTotal,
                orderItems
            } = data
            const items = orderItems.map(item => {
                return {
                    "item_id": item.productId,
                    "item_name": item.productId,
                    "quantity": item.quantity,
                    "price": item.price?.amount,
                    "item_category_id":166
                }
            })

            window.axon("track", "purchase", {
                "currency": orderTotal?.currency, "value": orderTotal?.amount,
                "transaction_id": transactionId,
                items: items
            });
        }
    } catch (e) {
        console.warn(e)
    }
}

export default {
    addToCart,
    pageView,
    purchase
}